@import "base";

/* =============================================================================
 *  Element Styling
 * ========================================================================== */

$input_block_max_width: 600px;

input[type="text"],
input[type="search"],
.form-text,
textarea,
select,
button.ui-multiselect{
  border:1px solid $form_item_border;
  background-color:white;
  padding:$input_default_v_space $input_default_h_space;
  outline:0;
  text-align:left;
  font-size:$input_default_font_size;
  line-height:$input_default_line_height !important;
  vertical-align:top;
  width:100%;
  max-width:$input_block_max_width;
  box-sizing: border-box;
  &:hover{
    border-color:$form_item_border_hover;
  }
  &:active,
  &:focus{
    border-color:$form_item_border_active;
  }
}

input[type="text"],
input.form-text,
input[type="search"],
select{
  // fix different height to text fields
  padding-top:0;
  padding-bottom:0;
  height:($input_default_v_space * 2) + $input_default_line_height + 2px; // 2px = Border
}

.form-submit,
button:not(.ui-multiselect){
  @include btn;
}

/* Fieldset */
fieldset {
  border:1px solid $grey_light;
  background:white;
  padding:$grid / 2;
  .fieldset-wrapper{
    padding-top:0;
    padding-bottom:0;
  }
  legend{
    border-radius:0;
    background:transparent;
    color:$text_color;
    font-weight:$font_weight_bold;
    display:block;
    padding:5px 5px;
    font-size:1.15em;
    margin-left:-5px;
    a,
    a:hover,
    a:active,
    a:focus{
      color:$text_color;
      text-decoration:none;
    }
  }
  fieldset{
    border:1px solid $grey_light;
    margin-bottom: $grid_vertical;
    &:not(.collapsed){
      box-shadow:0 1px 0 #fff, 0 1px 0 #fff inset;
    }
    legend{
      font-size:1em;
    }
  }
}

/* Placeholder */
::-webkit-input-placeholder {
   color: lighten($text_color, 15%);
}
:-moz-placeholder { /* Firefox 18- */
   color: lighten($text_color, 15%);
}
::-moz-placeholder {  /* Firefox 19+ */
   color: lighten($text_color, 15%);
}
:-ms-input-placeholder {
   color: lighten($text_color, 15%);
}

// IE-FIX
.lt-ie9 input[type="text"],
.lt-ie9 input.form-text{
  max-width:none;
}

// Chrome-FIXES
*:focus {
  // Remove this ugly outline thing on focused elements
  outline: 0;
}

/* =============================================================================
 *   Special Form Items
 * ========================================================================== */

/* Progress Bars */
.progress .bar{
  border:1px solid $grey;
  background:white;
  .filled{
    background-color:$highlight;
  }
}

/* Textarea Grippie */
.form-textarea-wrapper textarea{
  resize: none;
  width:100%;
  max-width:100%;
}
.resizable-textarea .grippie {
  background: none repeat scroll 0 0 #EEEEEE;
  opacity:0.5;
  border: 0 none;
  border-radius: 4px 4px 4px 4px;
  float: right;
  height: 30px;
  margin: -35px 30px 0 0;
  position: relative;
  width: 30px;
  z-index: 5;
}
.resizable-textarea .grippie:hover { opacity:1.0; }
.resizable-textarea .grippie:after {
  bottom: 3px;
  @include get-ico-only('double-arrow-down');
  font-family: "iconfont";
  font-size: 16px;
  position: absolute;
  right: 9px;
}

/* Autocomplete Fields */
html.js input.form-autocomplete{
  background-position: right $thin_grid top ($input_default_v_space + 3);
}

/*
 * Password confirmation
 */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* jQuery Widgets */
/* -- Select & Multiselect */
button.ui-multiselect{
  width:95% !important;
  position:relative;
  &.ui-state-active{
    border-color:$highlight;
  }
  .ui-icon{
    position:absolute;
    right:$input_default_h_space;
    top:50%;
  }
  .ui-icon-triangle-1-s{
    content:" ";
    margin-top:-3px;
    border-width:6px;
    border-style:solid;
    border-color:$text_color transparent transparent transparent;
  }
}
.ui-multiselect-menu{
  width:auto;
  // display:none; prevents animation
  @include flyout;
  display:block !important;
  max-height:0;
  padding-top:0;
  padding-bottom:0;
  overflow:hidden;
  min-width:280px;
  transition-property: max-height, padding;
  transition-duration: 350ms;
  &[style*="top"]{
    // jquery set some values on first opening.. except display:block ... so we need some kinda hack.
    max-height:500px;
    padding-top:$thin_grid;
    padding-bottom:$thin_grid;
  }
  &[style*="none"]{
    max-height:0;
    padding-top:0;
    padding-bottom:0;
  }
}
.ui-multiselect-checkboxes{
  margin:10px 0 0 0;
  padding:0 2px 0 0;
  overflow:auto;
  label{
    cursor:pointer;
    display:block;
    line-height:1.1em;
    border-bottom:1px solid $grey_light;
    padding:8px 4px 8px 28px;
    &:hover{
      color:$highlight_text_color;
      border-color:$highlight;
      background-color:$highlight;
    }
    input{
      margin-left:-23px;
      float:left;
      margin-top:2px;
      outline:2px solid $grey_light;
      &[aria-selected="true"]{
        outline:2px solid $highlight_lighter;
      }
    }
  }
  li{
    &:last-child{
      label{
        border-bottom:0 none;
      }
    }
  }
}
/* -- -- Filter */
$multiselect_filter_height:40px;
.ui-multiselect-filter{
  margin-bottom:5px;
  height:$multiselect_filter_height;
  text-indent:-9000px; // remove the damn label
  input{
    width:100% !important;
    height:$multiselect_filter_height;
    padding:0 5px;
    font-size:$font_small;
    float:left;
  }
  + .ui-helper-reset{
    list-style:none;
    padding:0;
    margin:0;
    display:flex;
    justify-content:space-between;
    a{
      @include btn(ghost, xs);
      font-size:$font_small;
    }
    .ui-multiselect-close{
      display:none;
    }
  }
}

/* Date Picker */
@import "components/_forms_datepicker";

/* Address Fields */

.addressfield-container-inline{
  max-width:$input_block_max_width;
  display:flex;
  .form-item{
    &:first-child{
      width:30%;
      min-width:80px;
    }
    &:last-child{
      width:70%;
      margin-right:0;
    }
  }
}

/* =============================================================================
 *   Drupal Forms
 * ========================================================================== */

.form-item{
  margin-top:0;
  label {
    font-weight: normal;
    text-align:left;
  }
}

/*
 * Highlight marks and form required mark
 */
.marker,
.form-required {
  color: #c00;
}

.form-item .description {
  font-size: 0.85em;
  line-height:normal;
  p{
    margin-bottom:0.25em;
  }
}

.form-checkboxes .form-item,
.form-radios .form-item {}


/* File Fields */
/* -- Auto Upload */
.auto_upload-processed + .form-submit{
  display:none !important;
}

/* Table (draggable) Fields */
.tabledrag-handle{
  position:relative;
  z-index:3;
  width:$tabledrag_icon_width;
 .handle{
    box-sizing:content-box;
  }
}
table.tabledrag-processed{
  width:100%;
}
tr.draggable{
  > td{
    padding-top:$thin_grid;
    border-bottom:1px solid $grey_light;
    &:first-child{
      padding-left:$tabledrag_icon_width + $thin_grid;
      position:relative;
      .tabledrag-handle{
        margin-left:($tabledrag_icon_width + $thin_grid) * -1;
      }
    }
    &.checkbox,
    &:last-child{
      text-align:center;
    }
  }
  .form-item{
    // reset regular field style
    border:0 none;
    box-shadow:none;
    padding-bottom:0;
  }
  &.drag{
    // While dragging
    td{
      opacity:0.8;
      background-color:$message_warning_background;
    }
  }
  &.drag-previous{
    // Dragged none saved item
    td{
      background-color:$message_warning_background;
    }
  }
  .tabledrag-changed{
    // Element that marks an changed tr
    color:$message_warning_background;
    display:block;
    width:20px;
    background:$message_warning_text;
    position:absolute;
    left:0;
    bottom:0;
    border-radius:0 50% 0 0;
    height: 20px;
    text-align:center;
    left: 0;
    line-height: 26px;
    vertical-align: middle;
  }
}

/* Image Fields */
.image-widget{
  /* BAZINGA SYNC */
  padding-left:$image_preview_width + ($grid / 2);
  position:relative;
  .filefield-sources-list{
    margin-left:($image_preview_width + ($grid / 2)) * -1;
  }
  .image-preview{
    float:left;
    width:$image_preview_width;
    margin-left:-($image_preview_width + ($grid / 2));
  }
  .form-file{
    margin-top:$input_default_v_space / 2;
    margin-left:($image_preview_width + ($grid / 2)) * -1;
  }
}

/*
 * The submit button
 */
.form-submit {}

.container-inline div,
.container-inline label {
  display: inline;
}

.form-actions{
  padding-top:1.5em;
  @include clearfix-after;
  input{
    margin-right:5px;
  }
}

form.node-form #edit-submit,
.webform-client-form .form-submit{
  @include btn('highlight');
}

form.node-form #edit-delete{
  @include btn('red');
}

@if $dock_form_actions == true{
  body.page-node-edit{ padding-bottom:30px; }
  form.node-form .form-actions{
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    background:white;
    border-top:1px solid $webks_grey_light;
    text-align:center;
    z-index:99;
    padding:5px 0 10px;
    margin:0;
    .form-submit{
      margin-right:3px !important;
    }
  }
}

/* Drupal Default Forms */
#user-profile-form input[type="text"],
#user-profile-form .form-text,
#user-profile-form textarea,
#user-profile-form .form-textarea-wrapper textarea, /* Override System.css */
#user-profile-form select,
.form-item-mollom-captcha input[type="text"]{ width:auto; }

/* Textareas */
.form-textarea-wrapper{ @include clearfix-after; }

.node-form .form-item,
.node-form .field-type-text-long-form,
.node-form .field-type-text-with-summary{
  @include cutline('bottom');
  .form-item{
    border:0 none;
    box-shadow:none;
    padding-bottom:0;
  }
}

// Fix video fields
.node-form{
  #field-videos-values {
    // hide duplicate field label
    thead > tr > th.field-label{
      display:none;
    }
  }
}

// Remove cutline from last childs
// #webksde-TF: This dont work well in drupal. The form markup is a complete CHAOS.
.fieldset-wrapper > .form-item:last-child,
.fieldset-wrapper > .form-wrapper:last-child .form-item{
  // border:0 none;
  // margin-bottom:0;
  // padding-bottom:0;
}

.views-row .field-type-text-long-form,
.views-row .field-type-text-with-summary,
.field-type-text-with-summary .field-type-text-with-summary,
.field-type-text-long-form .form-item,
.field-type-text-with-summary .form-item,
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item{
  margin-bottom:0;
  padding-bottom:0;
  border:0 none;
  box-shadow:none;
}

/* Labels & Descriptions */
.form-item label { /* The label for a form element */
  display: block;
  font-weight: normal;
}
.form-item label.option { /* The label for a radio button or checkbox */
  display: inline;
  font-weight: normal;
  vertical-align:middle;
}
.inline-fields label{ /* Inline styled Form Fields */ display:inline-block; width:30%;  }

.form-item + .description,
.form-item .description{ /* The descriptive help text (separate from the label) */
  font-size: $font_small;
  padding: 0.5em 0;
  color:$form_description_color;
  line-height:$line_height_medium;
  font-style:italic;
  @include ico('circle-info');
}
.form-item:hover + .description,
.form-item:hover > .description,
.form-item .description:hover {
  color:$text_color;
}

.fieldset-description{
  @include message(info);
}

.form-type-radio .description,
.form-type-checkbox .description { /* Reset margin for radius & checkboxes */
  margin-left: 0;
}
.form-checkboxes .form-item,
.form-radios .form-item{ /* Pack groups of checkboxes and radio buttons closer together */
  float:none;
  padding: 0.75em 0 3px 1%;
  margin:0;
  line-height:normal;
}

/* Date Fields */
.date-padding{
  display:flex;
  label{
    color:$grey;
  }
  .form-item{
    margin-right:$thin_grid;
    margin-bottom:0;
  }
}
.date-no-float{
  &:last-child{
    margin-bottom:0;
  }
}

/* Media Fields */
.form-media{
  .preview{
    &:empty{
      display:none;
    }
  }
}

.node-form{
  > div > .form-wrapper,
  > .form-wrapper{
    > .fieldset-wrapper{
      // Stretch cutlines to full with
      > .form-item,
      > .form-wrapper > .form-item{
        margin-left: $grid / -2;
        margin-right: $grid / -2;
        padding-left: $grid / 2;
        padding-right: $grid / 2;
      }
      // > *{
      //   &:nth-child(2n){
      //     background-color: $bluish_grey_lightest;
      //     margin-left: $grid / -2;
      //     margin-right: $grid / -2;
      //     padding: $grid / 2;
      //   }
      // }
      legend{
        background: $highlight !important;
        color: $highlight_text_color !important;
        padding:.5rem 1rem;
        a{
          color: currentColor;
        }
      }
      fieldset{
        border-color: $highlight;
        fieldset{
          border-color: $grey_dark;
          legend{
            background-color: $grey_dark !important;
            padding:.35rem .75rem;
          }
        }
      }
    }
  }
}

/* Office Hours Fields */
@import "components/_module_opening_hours";

/* =============================================================================
 *   Form Specific
 * ========================================================================== */

/* View Filter Style */
.filter-guidelines{ margin:10px; }
.filter-guidelines .tips{ padding:10px 0 0 0; }

/* ALL Search Forms */
.search-form{
  margin:0;
  .container-inline{
    display:flex;
  }
  .form-type-searchfield{
    position:relative;
    display:block;
    float:left;
    width:64%;
    margin-right:1%;
    flex:1 1 64%;
    @include ico('search', $grey_dark);
    &::before{
      position: absolute;
      top: 50%;
      line-height:1em;
      margin-top:($icon_size_normal / 2) * -1;
      left: 12px;
    }
  }
  .form-text{
    padding:4px 6px 4px 35px;
    width:100%;
    height:($btn_default_v_space * 2) + $input_default_font_size + 2; // fit the button next to it
    vertical-align: middle;
  }
  &:hover .form-item::before{
    color:$grey_dark;
  }
  .form-actions{
    display:block;
    float:left;
    padding:0;
    margin:0;
    width:35%;
    flex:1 1 auto;
    .form-submit{
      width:100%;
      margin:0;
      text-align:center;
    }
  }
}

/* Search (Block) */
#edit-advanced{
  .fieldset-legend{
    margin-left:-10px;
    a{
      @include btn('default', 'default', 'process');
    }
  }
}

/* Search (Page) */
.search-results{
  margin:0;
  padding:0;
  .search-result{
    border-bottom:1px solid $grey;
    .title{
      margin-top:0;
      margin-bottom:0.25em;
    }
  }
}

/* Registrierung */

%user-register-form{
  fieldset{
    @include box-style;
  }
  #edit-submit{
    @include btn('green', 'xl');
  }
}

#user-register-form,
#edit-register{
  @extend %user-register-form;
}

#edit-register{
  // Register forms inside node forms (for non registred users)
  @include box-style();
  margin-top:$grid_vertical;
}

/* Login */
.not-logged-in.page-user .page-title,
.not-logged-in.page-toboggan-denied .page-title {
  @include ico('locked');
}

/* Newsletter Subscriptions */
#edit-subscriptions{
  // TODO ID ersetzen
  @include box-style;
  margin-bottom:1em;
  .form-text{
    width:100%;
  }
  .fieldset-wrapper{
    padding-top:0;
  }
  #edit-newsletters{
    margin-bottom:1em;
  }
}

/* User Profile */
#user-profile-form{
  @include box-style;
}
.pane-user-simplenews{
  h3{
    margin:0;
    font-size:1em;
  }
  dl,
  dd{
    margin:0;
  }
}


/* Mollom */
.form-item-mollom-captcha{
  padding-left:$grid / 2;
  padding-right: $grid / 2;
}

/* =============================================================================
 *   Webforms
 * ========================================================================== */
.webform-confirmation{
  @include box-style;
}
.webform-client-form{
  @include box-style;
  .form-submit{
    float:right;
  }
  .form-text,
  .form-select:not(.day):not(.month):not(.year),
  .form-textarea{
    width:100%;
    @include box-sizing('border-box');
  }
  fieldset{
    border-bottom:1px solid $grey;
    margin-top:10px;
    &:last-of-type{
      // border:0 none;
      // padding-bottom:0;
    }
  }
}
// Make "Back to form"-Link a button
.page-node-done .links {
  margin-top:1em;
  a{
    @include btn ('default', 'default', 'arrow-left');
  }
}

// Webform Date fields
.webform-component-date .webform-datepicker{
  display:flex;
  .webform-calendar{
    align-self:center;
    padding:3px;
    width:24px;
    height:21px;
    flex: 0 0 18px;
  }
}

/* Form Specific */
/* -- Kontakt */
#webform-component-ihre-nachricht legend,
#webform-component-kontakt legend{ position:relative; padding-left:40px; }
#webform-component-ihre-nachricht legend::before,
#webform-component-kontakt legend::before{
  position:absolute;
  left:0;
  top:0;
  font-family:"iconfont";
  line-height:33px;
  width:33px;
  height:33px;
  background:$grey_dark;
  color:#fff;
  vertical-align:middle;
  text-align:center;
  border-radius:100%;
  @include get-ico-only('form');
}
#webform-component-kontakt legend::before{ @include get-ico-only('user'); }
/* -- -- Anfahrt widget */
#form-destination{
  input[type="text"]{
    width:64%;
    margin-right:1%;
    float:left;
    padding:5px;
  }
  .form-submit{
    width:35%;
    float:left;
    white-space:normal;
    padding:5px;
    height:auto;
  }
}

// Media Overlay
.ui-front.media-wrapper{
  background:white;
  z-index:999999 !important;
  .ui-dialog-titlebar{
    top:0;
    .ui-dialog-titlebar-close{

    }
    .ui-icon-closethick{
      text-align: center;
      width: 100%;
      position: static;
      left: 0;
      top: 0;
      margin-left: 0;
      margin-top:0;
      display: block;
      line-height: 14px;
      &::before{
        content:"x";
        font-weight:$font-weight-bold;
      }
    }
  }
  .ui-dialog-title{
    width:auto;
    margin:0;
    float:none;
  }
}
.ui-widget-overlay.ui-front{
  background:rgba(0,0,0,.5);
  position:fixed;
  left:0;
  top:0;
  z-index:999998 !important;
  height:100%;
  width:100%;
}
