@charset "UTF-8";
/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/* =============================================================================
 *  Element Styling
 * ========================================================================== */
input[type="text"],
input[type="search"],
.form-text,
textarea,
select,
button.ui-multiselect {
  border: 1px solid #d0d0d0;
  background-color: white;
  padding: 12px 12px;
  outline: 0;
  text-align: left;
  font-size: 16px;
  line-height: 18px !important;
  vertical-align: top;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box; }
  input[type="text"]:hover,
  input[type="search"]:hover,
  .form-text:hover,
  textarea:hover,
  select:hover,
  button.ui-multiselect:hover {
    border-color: #959ba4; }
  input[type="text"]:active, input[type="text"]:focus,
  input[type="search"]:active,
  input[type="search"]:focus,
  .form-text:active,
  .form-text:focus,
  textarea:active,
  textarea:focus,
  select:active,
  select:focus,
  button.ui-multiselect:active,
  button.ui-multiselect:focus {
    border-color: #0e4dac; }

input[type="text"],
input.form-text,
input[type="search"],
select {
  padding-top: 0;
  padding-bottom: 0;
  height: 44px; }

.form-submit,
button:not(.ui-multiselect) {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #0e4dac !important;
  background-color: #0e4dac !important;
  color: #fff !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }
  .form-submit:hover,
  button:not(.ui-multiselect):hover {
    border-color: #1058c4 !important;
    background-color: #1058c4 !important;
    color: #fff !important; }
  .form-submit:active, .form-submit:focus,
  button:not(.ui-multiselect):active,
  button:not(.ui-multiselect):focus {
    border-color: #0c4294 !important;
    background-color: #0c4294 !important;
    color: #fff !important; }
  .form-submit:hover,
  button:not(.ui-multiselect):hover {
    text-decoration: none; }

/* Fieldset */
fieldset {
  border: 1px solid #ebebeb;
  background: white;
  padding: 21.5px; }
  fieldset .fieldset-wrapper {
    padding-top: 0;
    padding-bottom: 0; }
  fieldset legend {
    border-radius: 0;
    background: transparent;
    color: #484848;
    font-weight: 600;
    display: block;
    padding: 5px 5px;
    font-size: 1.15em;
    margin-left: -5px; }
    fieldset legend a,
    fieldset legend a:hover,
    fieldset legend a:active,
    fieldset legend a:focus {
      color: #484848;
      text-decoration: none; }
  fieldset fieldset {
    border: 1px solid #ebebeb;
    margin-bottom: 25px; }
    fieldset fieldset:not(.collapsed) {
      box-shadow: 0 1px 0 #fff, 0 1px 0 #fff inset; }
    fieldset fieldset legend {
      font-size: 1em; }

/* Placeholder */
::-webkit-input-placeholder {
  color: #6e6e6e; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #6e6e6e; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #6e6e6e; }

:-ms-input-placeholder {
  color: #6e6e6e; }

.lt-ie9 input[type="text"],
.lt-ie9 input.form-text {
  max-width: none; }

*:focus {
  outline: 0; }

/* =============================================================================
 *   Special Form Items
 * ========================================================================== */
/* Progress Bars */
.progress .bar {
  border: 1px solid #959ba4;
  background: white; }
  .progress .bar .filled {
    background-color: #0e4dac; }

/* Textarea Grippie */
.form-textarea-wrapper textarea {
  resize: none;
  width: 100%;
  max-width: 100%; }

.resizable-textarea .grippie {
  background: none repeat scroll 0 0 #EEEEEE;
  opacity: 0.5;
  border: 0 none;
  border-radius: 4px 4px 4px 4px;
  float: right;
  height: 30px;
  margin: -35px 30px 0 0;
  position: relative;
  width: 30px;
  z-index: 5; }

.resizable-textarea .grippie:hover {
  opacity: 1.0; }

.resizable-textarea .grippie:after {
  bottom: 3px;
  content: "";
  font-family: "iconfont";
  font-size: 16px;
  position: absolute;
  right: 9px; }

/* Autocomplete Fields */
html.js input.form-autocomplete {
  background-position: right 15px top 15px; }

/*
 * Password confirmation
 */
.password-parent,
.confirm-parent {
  margin: 0; }

/* jQuery Widgets */
/* -- Select & Multiselect */
button.ui-multiselect {
  width: 95% !important;
  position: relative; }
  button.ui-multiselect.ui-state-active {
    border-color: #0e4dac; }
  button.ui-multiselect .ui-icon {
    position: absolute;
    right: 12px;
    top: 50%; }
  button.ui-multiselect .ui-icon-triangle-1-s {
    content: " ";
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: #484848 transparent transparent transparent; }

.ui-multiselect-menu {
  width: auto;
  background: white;
  color: #484848;
  padding: 20px;
  box-shadow: 0px 8px 7px -2px rgba(0, 0, 0, 0.4);
  min-width: 260px;
  z-index: 999;
  border: 0 none;
  position: absolute;
  top: 100%;
  left: 0;
  display: block !important;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  min-width: 280px;
  transition-property: max-height, padding;
  transition-duration: 350ms; }
  .ui-multiselect-menu[style*="top"] {
    max-height: 500px;
    padding-top: 15px;
    padding-bottom: 15px; }
  .ui-multiselect-menu[style*="none"] {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0; }

.ui-multiselect-checkboxes {
  margin: 10px 0 0 0;
  padding: 0 2px 0 0;
  overflow: auto; }
  .ui-multiselect-checkboxes label {
    cursor: pointer;
    display: block;
    line-height: 1.1em;
    border-bottom: 1px solid #ebebeb;
    padding: 8px 4px 8px 28px; }
    .ui-multiselect-checkboxes label:hover {
      color: #fff;
      border-color: #0e4dac;
      background-color: #0e4dac; }
    .ui-multiselect-checkboxes label input {
      margin-left: -23px;
      float: left;
      margin-top: 2px;
      outline: 2px solid #ebebeb; }
      .ui-multiselect-checkboxes label input[aria-selected="true"] {
        outline: 2px solid #78aaf4; }
  .ui-multiselect-checkboxes li:last-child label {
    border-bottom: 0 none; }

/* -- -- Filter */
.ui-multiselect-filter {
  margin-bottom: 5px;
  height: 40px;
  text-indent: -9000px; }
  .ui-multiselect-filter input {
    width: 100% !important;
    height: 40px;
    padding: 0 5px;
    font-size: 12px;
    float: left; }
  .ui-multiselect-filter + .ui-helper-reset {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between; }
    .ui-multiselect-filter + .ui-helper-reset a {
      border-width: 1px;
      border-style: solid;
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.05em;
      cursor: pointer;
      text-align: left;
      text-decoration: none;
      vertical-align: top;
      user-select: none;
      background-color: transparent !important;
      border-color: #d0d0d0 !important;
      color: #484848 !important;
      padding: 6px 6px;
      border-radius: 5px;
      line-height: 1em;
      font-size: 12px;
      font-size: 12px; }
      .ui-multiselect-filter + .ui-helper-reset a:hover {
        background-color: transparent !important;
        border-color: #e4e4e4 !important;
        color: #0e4dac !important; }
      .ui-multiselect-filter + .ui-helper-reset a:active, .ui-multiselect-filter + .ui-helper-reset a:focus {
        background-color: transparent !important;
        border-color: #c3c3c3 !important;
        color: #484848 !important; }
      .ui-multiselect-filter + .ui-helper-reset a:hover {
        text-decoration: none; }
    .ui-multiselect-filter + .ui-helper-reset .ui-multiselect-close {
      display: none; }

/* Date Picker */
#ui-datepicker-div {
  background: white;
  color: #484848;
  padding: 20px;
  box-shadow: 0px 8px 7px -2px rgba(0, 0, 0, 0.4);
  min-width: 260px;
  z-index: 999;
  border: 0 none;
  position: absolute;
  top: 100%;
  left: 0;
  font-family: "Open Sans", Verdana, Geneva, "DejaVu Sans", Arial, Helvetica, sans-serif; }
  #ui-datepicker-div .ui-datepicker-title select {
    padding-left: 3px;
    padding-right: 3px; }
  #ui-datepicker-div .ui-datepicker-header > a {
    height: 30px;
    width: 30px;
    top: 11px;
    line-height: 30px;
    border-radius: 50%;
    background: #074093;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    text-align: center; }
    #ui-datepicker-div .ui-datepicker-header > a .ui-icon {
      display: none; }
    #ui-datepicker-div .ui-datepicker-header > a::before {
      font-family: "iconfont";
      vertical-align: middle;
      text-align: center;
      width: 100%; }
    #ui-datepicker-div .ui-datepicker-header > a.ui-datepicker-prev::before {
      content: ""; }
    #ui-datepicker-div .ui-datepicker-header > a.ui-datepicker-next::before {
      content: ""; }
  #ui-datepicker-div .ui-datepicker-calendar td {
    text-align: center; }
    #ui-datepicker-div .ui-datepicker-calendar td > a {
      height: 30px;
      width: 30px;
      line-height: 24px;
      display: inline-block;
      border-radius: 50%;
      text-align: center; }
      #ui-datepicker-div .ui-datepicker-calendar td > a:hover {
        background: #0e4dac;
        color: #fff; }
    #ui-datepicker-div .ui-datepicker-calendar td.ui-datepicker-today > a {
      background-color: #78aaf4;
      color: #fff; }

/* Address Fields */
.addressfield-container-inline {
  max-width: 600px;
  display: flex; }
  .addressfield-container-inline .form-item:first-child {
    width: 30%;
    min-width: 80px; }
  .addressfield-container-inline .form-item:last-child {
    width: 70%;
    margin-right: 0; }

/* =============================================================================
 *   Drupal Forms
 * ========================================================================== */
.form-item {
  margin-top: 0; }
  .form-item label {
    font-weight: normal;
    text-align: left; }

/*
 * Highlight marks and form required mark
 */
.marker,
.form-required {
  color: #c00; }

.form-item .description {
  font-size: 0.85em;
  line-height: normal; }
  .form-item .description p {
    margin-bottom: 0.25em; }

/* File Fields */
/* -- Auto Upload */
.auto_upload-processed + .form-submit {
  display: none !important; }

/* Table (draggable) Fields */
.tabledrag-handle {
  position: relative;
  z-index: 3;
  width: 41px; }
  .tabledrag-handle .handle {
    box-sizing: content-box; }

table.tabledrag-processed {
  width: 100%; }

tr.draggable > td {
  padding-top: 15px;
  border-bottom: 1px solid #ebebeb; }
  tr.draggable > td:first-child {
    padding-left: 56px;
    position: relative; }
    tr.draggable > td:first-child .tabledrag-handle {
      margin-left: -56px; }
  tr.draggable > td.checkbox, tr.draggable > td:last-child {
    text-align: center; }

tr.draggable .form-item {
  border: 0 none;
  box-shadow: none;
  padding-bottom: 0; }

tr.draggable.drag td {
  opacity: 0.8;
  background-color: #fcf8e3; }

tr.draggable.drag-previous td {
  background-color: #fcf8e3; }

tr.draggable .tabledrag-changed {
  color: #fcf8e3;
  display: block;
  width: 20px;
  background: #8a6d3b;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 50% 0 0;
  height: 20px;
  text-align: center;
  left: 0;
  line-height: 26px;
  vertical-align: middle; }

/* Image Fields */
.image-widget {
  /* BAZINGA SYNC */
  padding-left: 131.5px;
  position: relative; }
  .image-widget .filefield-sources-list {
    margin-left: -131.5px; }
  .image-widget .image-preview {
    float: left;
    width: 110px;
    margin-left: -131.5px; }
  .image-widget .form-file {
    margin-top: 6px;
    margin-left: -131.5px; }

/*
 * The submit button
 */
.container-inline div,
.container-inline label {
  display: inline; }

.form-actions {
  padding-top: 1.5em; }
  .form-actions::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .form-actions input {
    margin-right: 5px; }

form.node-form #edit-submit,
.webform-client-form .form-submit {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #0e4dac !important;
  background-color: #0e4dac !important;
  color: #fff !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }
  form.node-form #edit-submit:hover,
  .webform-client-form .form-submit:hover {
    border-color: #1058c4 !important;
    background-color: #1058c4 !important;
    color: #fff !important; }
  form.node-form #edit-submit:active, form.node-form #edit-submit:focus,
  .webform-client-form .form-submit:active,
  .webform-client-form .form-submit:focus {
    border-color: #0c4294 !important;
    background-color: #0c4294 !important;
    color: #fff !important; }
  form.node-form #edit-submit:hover,
  .webform-client-form .form-submit:hover {
    text-decoration: none; }

form.node-form #edit-delete {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #d02b2b !important;
  border-color: #d02b2b !important;
  text-shadow: 0 1px 1px #dd5b5b;
  color: white !important;
  padding: 12px 12px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }
  form.node-form #edit-delete:hover {
    background-color: #d53535 !important;
    border-color: #d53535 !important;
    color: white !important; }
  form.node-form #edit-delete:active, form.node-form #edit-delete:focus {
    background-color: #c32828 !important;
    border-color: #c32828 !important;
    color: #7b1a1a !important; }
  form.node-form #edit-delete:hover {
    text-decoration: none; }

/* Drupal Default Forms */
#user-profile-form input[type="text"],
#user-profile-form .form-text,
#user-profile-form textarea,
#user-profile-form .form-textarea-wrapper textarea,
#user-profile-form select,
.form-item-mollom-captcha input[type="text"] {
  width: auto; }

/* Textareas */
.form-textarea-wrapper::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden; }

.node-form .form-item,
.node-form .field-type-text-long-form,
.node-form .field-type-text-with-summary {
  box-shadow: 0 1px 0 #ebebeb;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  .node-form .form-item .form-item,
  .node-form .field-type-text-long-form .form-item,
  .node-form .field-type-text-with-summary .form-item {
    border: 0 none;
    box-shadow: none;
    padding-bottom: 0; }

.node-form #field-videos-values thead > tr > th.field-label {
  display: none; }

.views-row .field-type-text-long-form,
.views-row .field-type-text-with-summary,
.field-type-text-with-summary .field-type-text-with-summary,
.field-type-text-long-form .form-item,
.field-type-text-with-summary .form-item,
.node-form .field-type-text-long-form .form-item,
.node-form .field-type-text-with-summary .form-item {
  margin-bottom: 0;
  padding-bottom: 0;
  border: 0 none;
  box-shadow: none; }

/* Labels & Descriptions */
.form-item label {
  /* The label for a form element */
  display: block;
  font-weight: normal; }

.form-item label.option {
  /* The label for a radio button or checkbox */
  display: inline;
  font-weight: normal;
  vertical-align: middle; }

.inline-fields label {
  /* Inline styled Form Fields */
  display: inline-block;
  width: 30%; }

.form-item + .description,
.form-item .description {
  /* The descriptive help text (separate from the label) */
  font-size: 12px;
  padding: 0.5em 0;
  color: #959ba4;
  line-height: 1.35em;
  font-style: italic; }
  .form-item + .description::before,
  .form-item .description::before {
    font-family: "iconfont";
    font-weight: normal !important;
    font-style: normal !important;
    margin-right: 4px;
    content: ""; }

.form-item:hover + .description,
.form-item:hover > .description,
.form-item .description:hover {
  color: #484848; }

.fieldset-description {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }
  .fieldset-description::before {
    font-family: "iconfont";
    position: absolute;
    left: 15px;
    top: 14px;
    font-size: 25px;
    line-height: normal; }
  .fieldset-description ul {
    margin-top: 0;
    margin-bottom: 0; }
  .fieldset-description a {
    color: #31708f; }
  .fieldset-description::before {
    content: ""; }

.form-type-radio .description,
.form-type-checkbox .description {
  /* Reset margin for radius & checkboxes */
  margin-left: 0; }

.form-checkboxes .form-item,
.form-radios .form-item {
  /* Pack groups of checkboxes and radio buttons closer together */
  float: none;
  padding: 0.75em 0 3px 1%;
  margin: 0;
  line-height: normal; }

/* Date Fields */
.date-padding {
  display: flex; }
  .date-padding label {
    color: #959ba4; }
  .date-padding .form-item {
    margin-right: 15px;
    margin-bottom: 0; }

.date-no-float:last-child {
  margin-bottom: 0; }

/* Media Fields */
.form-media .preview:empty {
  display: none; }

.node-form > div > .form-wrapper > .fieldset-wrapper > .form-item,
.node-form > div > .form-wrapper > .fieldset-wrapper > .form-wrapper > .form-item,
.node-form > .form-wrapper > .fieldset-wrapper > .form-item,
.node-form > .form-wrapper > .fieldset-wrapper > .form-wrapper > .form-item {
  margin-left: -21.5px;
  margin-right: -21.5px;
  padding-left: 21.5px;
  padding-right: 21.5px; }

.node-form > div > .form-wrapper > .fieldset-wrapper legend,
.node-form > .form-wrapper > .fieldset-wrapper legend {
  background: #0e4dac !important;
  color: #fff !important;
  padding: .5rem 1rem; }
  .node-form > div > .form-wrapper > .fieldset-wrapper legend a,
  .node-form > .form-wrapper > .fieldset-wrapper legend a {
    color: currentColor; }

.node-form > div > .form-wrapper > .fieldset-wrapper fieldset,
.node-form > .form-wrapper > .fieldset-wrapper fieldset {
  border-color: #0e4dac; }
  .node-form > div > .form-wrapper > .fieldset-wrapper fieldset fieldset,
  .node-form > .form-wrapper > .fieldset-wrapper fieldset fieldset {
    border-color: #3f3f3f; }
    .node-form > div > .form-wrapper > .fieldset-wrapper fieldset fieldset legend,
    .node-form > .form-wrapper > .fieldset-wrapper fieldset fieldset legend {
      background-color: #3f3f3f !important;
      padding: .35rem .75rem; }

/* Office Hours Fields */
.field-type-office-hours .oh-wrapper {
  display: table; }

.field-type-office-hours .oh-display {
  display: table-row;
  border-bottom: 1px solid transparent; }

.field-type-office-hours .oh-display-label {
  display: table-cell;
  background: #0e4dac;
  color: #fff;
  padding: 2px 4px;
  text-align: right;
  border-bottom: 1px solid white; }

.field-type-office-hours .oh-display-times {
  display: table-cell;
  padding: 2px 4px; }

.field-type-office-hours .oh-current-open,
.field-type-office-hours .oh-current-closed {
  display: inline-block;
  font-size: 12px;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: #0e4dac !important;
  color: #fff !important; }
  .field-type-office-hours .oh-current-open::before,
  .field-type-office-hours .oh-current-closed::before {
    display: inline-block;
    margin-right: 3px; }
  .field-type-office-hours .oh-current-open + .oh-wrapper,
  .field-type-office-hours .oh-current-closed + .oh-wrapper {
    margin-top: .5rem; }

.field-type-office-hours .oh-current-open {
  background-color: #bcc826 !important;
  color: white !important; }

.field-type-office-hours .oh-current-closed {
  background-color: #d02b2b !important;
  color: white !important; }

.field-widget-office-hours thead {
  text-align: left; }

.field-widget-office-hours table {
  width: 100%;
  table-layout: fixed; }
  .field-widget-office-hours table tr.even .office-hours-block label:first-child {
    font-weight: 600; }
  .field-widget-office-hours table tr.odd {
    position: relative;
    top: -3px; }
  .field-widget-office-hours table td {
    text-align: right; }

.field-widget-office-hours .office-hours-block {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin: 0;
  padding: 3px 5px 3px 15px !important;
  background-color: #ebebeb;
  border-radius: 5px;
  vertical-align: middle;
  line-height: 42px; }
  .field-widget-office-hours .office-hours-block label:first-child {
    display: inline-block;
    width: 120px;
    text-align: left !important; }
  .field-widget-office-hours .office-hours-block .form-item {
    margin-bottom: 0; }

.field-widget-office-hours .oh-clear-link {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent !important;
  border-color: #d0d0d0 !important;
  color: #484848 !important;
  padding: 6px 6px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px;
  margin-top: 3px; }
  .field-widget-office-hours .oh-clear-link:hover {
    background-color: transparent !important;
    border-color: #e4e4e4 !important;
    color: #0e4dac !important; }
  .field-widget-office-hours .oh-clear-link:active, .field-widget-office-hours .oh-clear-link:focus {
    background-color: transparent !important;
    border-color: #c3c3c3 !important;
    color: #484848 !important; }
  .field-widget-office-hours .oh-clear-link:hover {
    text-decoration: none; }

.field-widget-office-hours .oh-clear-link:hover {
  background-color: #d53535 !important;
  border-color: #d53535 !important;
  color: white !important; }

.field-widget-office-hours .oh-clear-link:active, .field-widget-office-hours .oh-clear-link:focus {
  background-color: #c32828 !important;
  border-color: #c32828 !important;
  color: #7b1a1a !important; }

.field-widget-office-hours .oh-add-more-link {
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent !important;
  border-color: #d0d0d0 !important;
  color: #484848 !important;
  padding: 6px 6px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px;
  margin-bottom: 10px; }
  .field-widget-office-hours .oh-add-more-link:hover {
    background-color: transparent !important;
    border-color: #e4e4e4 !important;
    color: #0e4dac !important; }
  .field-widget-office-hours .oh-add-more-link:active, .field-widget-office-hours .oh-add-more-link:focus {
    background-color: transparent !important;
    border-color: #c3c3c3 !important;
    color: #484848 !important; }
  .field-widget-office-hours .oh-add-more-link:hover {
    text-decoration: none; }
  .field-widget-office-hours .oh-add-more-link::before {
    font-family: "iconfont";
    font-weight: normal !important;
    font-style: normal !important;
    margin-right: 4px;
    content: "";
    color: #bcc826; }
  .field-widget-office-hours .oh-add-more-link + .office-hours-block {
    margin-bottom: 10px; }

/* =============================================================================
 *   Form Specific
 * ========================================================================== */
/* View Filter Style */
.filter-guidelines {
  margin: 10px; }

.filter-guidelines .tips {
  padding: 10px 0 0 0; }

/* ALL Search Forms */
.search-form {
  margin: 0; }
  .search-form .container-inline {
    display: flex; }
  .search-form .form-type-searchfield {
    position: relative;
    display: block;
    float: left;
    width: 64%;
    margin-right: 1%;
    flex: 1 1 64%; }
    .search-form .form-type-searchfield::before {
      font-family: "iconfont";
      font-weight: normal !important;
      font-style: normal !important;
      margin-right: 4px;
      content: "";
      color: #3f3f3f; }
    .search-form .form-type-searchfield::before {
      position: absolute;
      top: 50%;
      line-height: 1em;
      margin-top: -9px;
      left: 12px; }
  .search-form .form-text {
    padding: 4px 6px 4px 35px;
    width: 100%;
    height: 42px;
    vertical-align: middle; }
  .search-form:hover .form-item::before {
    color: #3f3f3f; }
  .search-form .form-actions {
    display: block;
    float: left;
    padding: 0;
    margin: 0;
    width: 35%;
    flex: 1 1 auto; }
    .search-form .form-actions .form-submit {
      width: 100%;
      margin: 0;
      text-align: center; }

/* Search (Block) */
#edit-advanced .fieldset-legend {
  margin-left: -10px; }
  #edit-advanced .fieldset-legend a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #0e4dac !important;
    background-color: #0e4dac !important;
    color: #fff !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px; }
    #edit-advanced .fieldset-legend a:hover {
      border-color: #1058c4 !important;
      background-color: #1058c4 !important;
      color: #fff !important; }
    #edit-advanced .fieldset-legend a:active, #edit-advanced .fieldset-legend a:focus {
      border-color: #0c4294 !important;
      background-color: #0c4294 !important;
      color: #fff !important; }
    #edit-advanced .fieldset-legend a::before {
      display: inline-block;
      font-family: "iconfont";
      font-style: normal;
      font-weight: normal;
      margin-right: 5px;
      text-align: center; }
      #edit-advanced .fieldset-legend a::before else {
        line-height: 1; }
    #edit-advanced .fieldset-legend a:hover {
      text-decoration: none; }

/* Search (Page) */
.search-results {
  margin: 0;
  padding: 0; }
  .search-results .search-result {
    border-bottom: 1px solid #959ba4; }
    .search-results .search-result .title {
      margin-top: 0;
      margin-bottom: 0.25em; }

/* Registrierung */
#user-register-form fieldset, #edit-register fieldset {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1; }

#user-register-form #edit-submit, #edit-register #edit-submit {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.05em;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: #bcc826 !important;
  border-color: #bcc826 !important;
  color: white !important;
  padding: 15px 15px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }
  #user-register-form #edit-submit:hover, #edit-register #edit-submit:hover {
    background-color: #c8d528 !important;
    border-color: #c8d528 !important;
    color: white !important; }
  #user-register-form #edit-submit:active, #edit-register #edit-submit:active, #user-register-form #edit-submit:focus, #edit-register #edit-submit:focus {
    background-color: #b0bb24 !important;
    border-color: #b0bb24 !important;
    color: #6b7216 !important; }
  #user-register-form #edit-submit:hover, #edit-register #edit-submit:hover {
    text-decoration: none; }

#edit-register {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1;
  margin-top: 25px; }

/* Login */
.not-logged-in.page-user .page-title::before,
.not-logged-in.page-toboggan-denied .page-title::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px;
  content: ""; }

/* Newsletter Subscriptions */
#edit-subscriptions {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1;
  margin-bottom: 1em; }
  #edit-subscriptions .form-text {
    width: 100%; }
  #edit-subscriptions .fieldset-wrapper {
    padding-top: 0; }
  #edit-subscriptions #edit-newsletters {
    margin-bottom: 1em; }

/* User Profile */
#user-profile-form {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1; }

.pane-user-simplenews h3 {
  margin: 0;
  font-size: 1em; }

.pane-user-simplenews dl,
.pane-user-simplenews dd {
  margin: 0; }

/* Mollom */
.form-item-mollom-captcha {
  padding-left: 21.5px;
  padding-right: 21.5px; }

/* =============================================================================
 *   Webforms
 * ========================================================================== */
.webform-confirmation {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1; }

.webform-client-form {
  border-width: 1px;
  border-style: solid;
  padding: 25px 43px;
  border-color: #ebebeb;
  border-radius: 0;
  background-color: white;
  overflow: hidden;
  *overflow: visible;
  *zoom: 1; }
  .webform-client-form .form-submit {
    float: right; }
  .webform-client-form .form-text,
  .webform-client-form .form-select:not(.day):not(.month):not(.year),
  .webform-client-form .form-textarea {
    width: 100%;
    box-sizing: "border-box";
    -moz-box-sizing: "border-box";
    -webkit-box-sizing: "border-box";
    -ms-box-sizing: "border-box"; }
  .webform-client-form fieldset {
    border-bottom: 1px solid #959ba4;
    margin-top: 10px; }

.page-node-done .links {
  margin-top: 1em; }
  .page-node-done .links a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #0e4dac !important;
    background-color: #0e4dac !important;
    color: #fff !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px; }
    .page-node-done .links a:hover {
      border-color: #1058c4 !important;
      background-color: #1058c4 !important;
      color: #fff !important; }
    .page-node-done .links a:active, .page-node-done .links a:focus {
      border-color: #0c4294 !important;
      background-color: #0c4294 !important;
      color: #fff !important; }
    .page-node-done .links a::before {
      display: inline-block;
      font-family: "iconfont";
      font-style: normal;
      font-weight: normal;
      margin-right: 5px;
      text-align: center;
      content: ""; }
      .page-node-done .links a::before else {
        line-height: 1; }
    .page-node-done .links a:hover {
      text-decoration: none; }

.webform-component-date .webform-datepicker {
  display: flex; }
  .webform-component-date .webform-datepicker .webform-calendar {
    align-self: center;
    padding: 3px;
    width: 24px;
    height: 21px;
    flex: 0 0 18px; }

/* Form Specific */
/* -- Kontakt */
#webform-component-ihre-nachricht legend,
#webform-component-kontakt legend {
  position: relative;
  padding-left: 40px; }

#webform-component-ihre-nachricht legend::before,
#webform-component-kontakt legend::before {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "iconfont";
  line-height: 33px;
  width: 33px;
  height: 33px;
  background: #3f3f3f;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  border-radius: 100%;
  content: ""; }

#webform-component-kontakt legend::before {
  content: ""; }

/* -- -- Anfahrt widget */
#form-destination input[type="text"] {
  width: 64%;
  margin-right: 1%;
  float: left;
  padding: 5px; }

#form-destination .form-submit {
  width: 35%;
  float: left;
  white-space: normal;
  padding: 5px;
  height: auto; }

.ui-front.media-wrapper {
  background: white;
  z-index: 999999 !important; }
  .ui-front.media-wrapper .ui-dialog-titlebar {
    top: 0; }
    .ui-front.media-wrapper .ui-dialog-titlebar .ui-icon-closethick {
      text-align: center;
      width: 100%;
      position: static;
      left: 0;
      top: 0;
      margin-left: 0;
      margin-top: 0;
      display: block;
      line-height: 14px; }
      .ui-front.media-wrapper .ui-dialog-titlebar .ui-icon-closethick::before {
        content: "x";
        font-weight: 600; }
  .ui-front.media-wrapper .ui-dialog-title {
    width: auto;
    margin: 0;
    float: none; }

.ui-widget-overlay.ui-front {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999998 !important;
  height: 100%;
  width: 100%; }
