// jquery.datepicker Styling (depends on the default stylesheets)
// Using the id to override the default styles more easy
#ui-datepicker-div{
  @include flyout();
  font-family:$font_first;
  .ui-datepicker-title{
    select{
      // Year & Month Select
      padding-left:3px;
      padding-right:3px;
    }
  }
  .ui-datepicker-header{
    > a{
      height:30px;
      width:30px;
      top:11px;
      line-height:30px;
      border-radius:50%;
      background:$highlight_dark;
      font-size:$font_small;
      color:$highlight_text_color;
      text-decoration:none;
      text-align:center;
      .ui-icon{
        display:none;
      }
      &::before{
        font-family:$iconfont;
        vertical-align:middle;
        text-align:center;
        width:100%;
      }
      &.ui-datepicker-prev{
        &::before{
          @include get-ico-only('arrow-thin-left');
        }
      }
      &.ui-datepicker-next{
        &::before{
          @include get-ico-only('arrow-thin-right');
        }
      }
    }
  }
  // Days
  .ui-datepicker-calendar{
    td{
      text-align:center;
      > a{
        height:30px;
        width:30px;
        line-height:24px;
        display:inline-block;
        border-radius:50%;
        text-align:center;
        &:hover{
          background:$highlight;
          color:$highlight_text_color;
        }
      }
      &.ui-datepicker-today{
        > a{
          background-color:$highlight_lighter;
          color:$highlight_text_color;
        }
      }
    }
  }
}