// Frontend Field Output
.field-type-office-hours{
  .oh-wrapper{
    display:table;
  }
  .oh-display{
    display:table-row;
    border-bottom:1px solid transparent;
  }
  .oh-display-label{
    display:table-cell;
    background:$highlight;
    color:$highlight_text_color;
    padding:2px 4px;
    text-align:right;
    border-bottom:1px solid white;
  }
  .oh-display-times{
    display:table-cell;
    padding:2px 4px;
  }
  .oh-current-open,
  .oh-current-closed{
    @include badge();
    + .oh-wrapper{
      margin-top:.5rem;
    }
  }
  .oh-current-open{
    @include badge-mod('green');
  }
  .oh-current-closed{
    @include badge-mod('red');
  }
}


// Form Field
.field-widget-office-hours{
  thead{
    text-align:left;
  }
  table{
    width:100%;
    table-layout:fixed;
    tr{
      &.even{
        .office-hours-block{
          label:first-child{
            font-weight:$font_weight_bold;
          }
        }
      }
      &.odd{
        position:relative;
        top:-3px;
      }
    }
    td{
      text-align:right;
    }
  }
  .office-hours-block{
    display:flex;
    justify-content:space-between;
    text-align:left;
    margin:0;
    padding:3px 5px 3px $thin_grid !important;
    background-color:$grey_light;
    border-radius:$border_radius_small;
    vertical-align:middle;
    line-height:42px;
    label:first-child{
      display:inline-block;
      width:120px;
      text-align:left !important;
    }
    .form-item{
      margin-bottom:0;
    }
  }
  .oh-clear-link{
    @include btn('ghost',xs);
    margin-top:3px;
  }
  .oh-clear-link{
    @include btn-mod('red',true);
  }
  .oh-add-more-link{
    text-decoration:none;
    @include btn('ghost',xs);
    @include ico('circle-plus',$green);
    margin-bottom:10px;
    + .office-hours-block{
      margin-bottom:10px;
    }
  }
}
